<script setup>
import { onMounted, ref, watch, getCurrentInstance } from "vue";
import Navbar from "@/components/layouts/Navbar.vue";
import Footer from "@/components/layouts/Footer.vue";
import Loading from "@/components/Loading.vue";
import * as Animation from "@/libs/gsap";

const LoadingInt = ref(false);

onMounted(() => {
  if (!localStorage.getItem("fl_theme")) {
    localStorage.setItem("fl_theme", "dark");
  }

  const currentTheme = localStorage.getItem("fl_theme")
    ? localStorage.getItem("fl_theme")
    : "dark";

  if (currentTheme) {
    document.documentElement.setAttribute("data-theme", currentTheme);
  }
  
  setTimeout(() => {
    LoadingInt.value = true;
  }, 2000);
  setTimeout(() => {
    Animation.handleActiveLink();
  }, 3000);
});
</script>

<template>
  <Loading v-show="!LoadingInt" />
  <div v-show="LoadingInt">
    <Navbar />
    <RouterView />
    <Footer />
  </div>
</template>