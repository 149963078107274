import appLogoIcon from "./app-logo.svg";
import appLogo2Icon from "./app-logo-2.svg";
import appLogo3Icon from "./app-logo-3.svg";
import appleIcon from "./apple.svg";
import googlePlayIcon from "./google-play.svg";
import goldLogoGroupIcon from "./gold-logo-group.svg";
import successIcon from "./success.svg";
import warningIcon from "./warning.svg";
import triangleIcon from "./triangle.svg";
import starsIcon from "./stars.svg";
import minusIcon from "./minus.svg";
import plusIcon from "./plus.svg";
import facebookIcon from "./facebook.svg";
import twitterIcon from "./twitter.svg";
import instagramIcon from "./instagram.svg";
import whatsappIcon from "./whatsapp.svg";
import linkedinIcon from "./linkedin.svg";
import youtubeIcon from "./youtube.svg";
import tiktokIcon from "./tiktok.svg";
import emailIcon from "./email.svg";
import phoneIcon from "./phone.svg";
import arrowBottomIcon from "./arrow-bottom.svg";
import moonIcon from "./moon.svg";
import sunIcon from "./sun.svg";
import chevronRightIcon from "./chevron-right.svg";
import chevronLeftIcon from "./chevron-left.svg";
import menu2RecIcon from "./menu-2-rec.svg";
import crossRecIcon from "./cross-rec.svg";
import vsIcon from "./vs.svg";
import maleIcon from "./male.svg";
import femaleIcon from "./female.svg";



const svgvendors = (app) => {
    return app
        .component("app-logo", appLogoIcon)
        .component("app-logo-2", appLogo2Icon)
        .component("app-logo-3", appLogo3Icon)
        .component("apple-icon", appleIcon)
        .component("google-play-icon", googlePlayIcon)
        .component("gold-logo-group-icon", goldLogoGroupIcon)
        .component("success-icon", successIcon)
        .component("warning-icon", warningIcon)
        .component("triangle-icon", triangleIcon)
        .component("stars-icon", starsIcon)
        .component("minus-icon", minusIcon)
        .component("plus-icon", plusIcon)
        .component("facebook-icon", facebookIcon)
        .component("twitter-icon", twitterIcon)
        .component("instagram-icon", instagramIcon)
        .component("whatsapp-icon", whatsappIcon)
        .component("linkedin-icon", linkedinIcon)
        .component("youtube-icon", youtubeIcon)
        .component("tiktok-icon", tiktokIcon)
        .component("email-icon", emailIcon)
        .component("phone-icon", phoneIcon)
        .component("arrow-bottom-icon", arrowBottomIcon)
        .component("moon-icon", moonIcon)
        .component("sun-icon", sunIcon)
        .component("chevron-right-icon", chevronRightIcon)
        .component("chevron-left-icon", chevronLeftIcon)
        .component("menu-2-rec-icon", menu2RecIcon)
        .component("cross-rec-icon", crossRecIcon)
        .component("vs-icon", vsIcon)
        .component("male-icon", maleIcon)
        .component("female-icon", femaleIcon)

    ;
};
export default svgvendors;