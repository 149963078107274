<script setup>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/libs/i18n";

const { t } = useI18n(); //
const router = useRouter();
const route = useRoute();
const fixedTop = ref(false);
const menuIsOpen = ref(false);
const currentTheme = ref(null);
const scrollFunction = () => {
  const mainNavbarSub = document.getElementById("navbar");
  if (mainNavbarSub) {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      fixedTop.value = true;
    } else {
      fixedTop.value = false;
    }
  }
};

const switchTheme = () => {
  menuIsOpen.value = false;
  currentTheme.value = localStorage.getItem("fl_theme")
    ? localStorage.getItem("fl_theme")
    : "dark";

  if (currentTheme.value == "dark") {
    currentTheme.value = "light";
    localStorage.setItem("fl_theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  } else {
    currentTheme.value = "dark";
    localStorage.setItem("fl_theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  }

  window.dispatchEvent(
    new CustomEvent("fl-theme-localstorage-changed", {
      detail: {
        storage: localStorage.getItem("fl_theme"),
      },
    })
  );
};

const changeLanguage = (code) => {
  menuIsOpen.value = false;
  router
    .push({
      name: router.currentRoute.name,
      params: {
        lang: code,
      },
    })
    .then(() => {
      window.location.reload();
    });
};

onMounted(() => {
  currentTheme.value = localStorage.getItem("fl_theme")
    ? localStorage.getItem("fl_theme")
    : "dark";
  scrollFunction();
  window.addEventListener("scroll", scrollFunction);
});
</script>
<template>
  <div id="not-fill-navbar"></div>
  <div
    id="navbar"
    :class="[
      fixedTop ? 'navbar fixed-top' : 'navbar',
      menuIsOpen ? 'menu-is-open' : '',
    ]"
  >
    <div class="navbar-sub">
      <router-link
        :to="{ name: 'home', params: { lang: $i18n.locale } }"
        class="brand"
      >
        <app-logo
      /></router-link>
      <button class="menu" @click="menuIsOpen = !menuIsOpen">
        <menu-2-rec-icon />
      </button>
      <div
        class="navbar-content-back"
        @click="menuIsOpen = !menuIsOpen"
        :class="menuIsOpen ? 'show' : ''"
      ></div>
      <div class="navbar-content" :class="menuIsOpen ? 'open' : ''">
        <div class="menu-header">
          <router-link
            :to="{ name: 'home', params: { lang: $i18n.locale } }"
            class="brand"
          >
            <app-logo
          /></router-link>
          <button class="close-menu" @click="menuIsOpen = !menuIsOpen">
            <cross-rec-icon />
          </button>
        </div>

        <div class="navbar-content-list">
          <router-link
            data-section="header"
            :to="{
              name: 'home',
              hash: '#header',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link active"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Home") }}
          </router-link>
          <router-link
            data-section="about"
            :to="{
              name: 'home',
              hash: '#about',
              params: {
                lang: $i18n.locale,
              },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.About the app") }}
          </router-link>
          <router-link
            data-section="awards"
            :to="{
              name: 'home',
              hash: '#awards',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Awards") }}
          </router-link>
          <router-link
            data-section="leagues"
            :to="{
              name: 'home',
              hash: '#leagues',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Leagues") }}
          </router-link>
          <router-link
            data-section="said"
            :to="{
              name: 'home',
              hash: '#said',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.What they said about us") }}
          </router-link>
          <router-link
            data-section="FAQ"
            :to="{
              name: 'home',
              hash: '#FAQ',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.FAQ") }}
          </router-link>
        </div>
        <div class="navbar-content-theme">
          <a class="switch-theme" @click="switchTheme">
            <sun-icon v-if="currentTheme == 'dark'" />
            <moon-icon v-else />
          </a>
          <a
            class="switch-locale"
            @click="changeLanguage($i18n.locale == 'ar' ? 'en' : 'ar')"
          >
            {{ $i18n.locale == "ar" ? $t("common.en") : $t("common.ar") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
